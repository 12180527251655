
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Button, Table, Form, Spinner, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import AddApiKeyForm from '../../newViews/avapCloud/components/forms/addKey';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { keys } from 'd3';

type ApiKey = {
  id: string;
  projectname: string;
  obex_project_id: string;
  type: string;
  apikey: string; // Actual API Key
  status: boolean; // Active, Revoked, Expired
  permissions: string;
  keycreatedat: string; // Last operation date
  user: string;
  api_products: string;
};

const Keys: FunctionComponent = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddKeyDrawerOpen, setIsAddKeyDrawerOpen] = useState(false);

  const mockKeys: ApiKey[] = [
    {
      id: '1',
      projectname: 'Project A - Key 1',
      obex_project_id: 'Project A',
      apikey: 'abcd1234xyz',
      status: true,
      type:'regular',
      user: '*',
      api_products: '*',
      permissions: 'Read',
      keycreatedat: '2024-11-01',
    },
    {
      id: '2',
      projectname: 'Project B - Key 2',
      obex_project_id: 'Project B',
      apikey: 'wxyz9876abc',
      type:'regular',
      status: true,
      user: '*',
      api_products: '*',
      permissions: 'Admin',
      keycreatedat: '2024-10-15',
    },
    {
      id: '3',
      projectname: 'Product X - Key 3',
      obex_project_id: 'Product X',
      apikey: 'mnop5678def',
      type:'regular',
      status: true,
      user: '*',
      api_products: '*',
      permissions: 'Write',
      keycreatedat: '2024-09-20',
    },
  ];

  useEffect(() => {
    fetchApiKeys('');
  }, []);

  const fetchApiKeys = async (filter: string) => {
    setLoading(true);

    const mockTokensRequst2 = await ObexRequestHandler.get(`/cert/get_keys?organization=${SessionService.ORGANIZATION}`) 

      const filteredKeys = mockTokensRequst2.data.filter(
        (key) =>
          key.projectname.toLowerCase().includes(filter.toLowerCase()) ||
          key.obex_project_id.toLowerCase().includes(filter.toLowerCase())
      );
      setApiKeys(filter === '' ? mockTokensRequst2.data : filteredKeys);
      setLoading(false);

  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleRevokeKey = (keyId: string) => {
    if (window.confirm('Are you sure you want to revoke this API key?')) {
      const updatedKeys = apiKeys.map((key) =>
        key.id === keyId ? { ...key, status: false } : key
      );
      setApiKeys(updatedKeys);
      alert('API Key revoked successfully');
    }
  };

  const handleRotateKey = (keyId: string) => {
    if (window.confirm('Are you sure you want to rotate this API key?')) {
      alert(`API Key ${keyId} rotated successfully`);
    }
  };

  const handleCopyKey = async (apiKey: string) => {
     await navigator.clipboard.writeText(apiKey).then(() => {
      alert('API Key copied to clipboard!');
    });
  };

  const handleAddKey = (newKey: ApiKey) => {
    setApiKeys((prevKeys) => [...prevKeys, newKey]);
    setIsAddKeyDrawerOpen(false);
  };

  return (
    <DashLayout sider={DashRouters} active="keys" sublinks={[]}>
      <Row id="topHeader" className="mb-4">
        <Col md="10">
          <div className="cld_wrapperTitle">
            <span className="cld_title w-100 margin-y-24px">
            Credential Manager <span style={{color:'#949498', fontSize:'14px'}}> /  API Keys Management</span>
              
            </span>
          </div>

        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-blue px-4 float-right  resBtn"
            onClick={() => setIsAddKeyDrawerOpen(true)}
          >
            Add API Key
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>





      <Row className="mt-4">
        <Col>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className="cld_borderWrapper">
              <span className='cld_sectionTitle'>Keys</span>

              <div className="searchContainer">

                <Form.Control
                  placeholder="Filter by Alias or Project"
                  value={filter}
                  onChange={handleFilterChange}
                  id='buscador'
                  required type="text"
                />

                <Button
                  className="customButton-blue px-4"
                  onClick={() => fetchApiKeys(filter)}
                >
                  Filter
                </Button>

              </div>
              <Table className='cld_tableWrapper' responsive>
                <thead>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className='textBold'>Alias</th>
                    <th style={{ width: '10%' }} className='textBold'>Proj</th>
                    <th style={{ width: '10%' }} className='textBold'>Type</th>
                    <th className='textBold'>Products</th>
                    <th style={{ width: '20%' }} className='textBold'>API Key</th>
                    <th className='textBold'>User</th>
                    <th className='textBold'>Created</th>
                    <th style={{ textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.map((key) => (
                    <tr className='tableRow' key={key.id}>
                      <td>{key.projectname}</td>
                      <td>{key.obex_project_id}</td>
                      <td>{key.type}</td>
                      <td>{key.api_products || '-'}</td>
                      <td>
                        <Row style={{ height:'24px', wordBreak:'break-all', overflow:'hidden' }}>
                          <Col xs="8">{key.apikey}</Col>
                          <Col xs="4">
                            <a style={{ cursor: 'pointer' }}
                              onClick={() => handleCopyKey(key.apikey)}
                            ><FontAwesomeIcon icon={faCopy} /></a>
                          </Col>
                        </Row>
                      </td>
                      <td>{key.user || '-'}</td>
                      <td>{key.keycreatedat.split('T')[0]}</td>
                      <td>
                        <div className="actionsWrapper">

                          <Dropdown className='my-auto' style={{ marginRight: '20px' }}>
                            <Dropdown.Toggle className="organizations" >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item key={1} onClick={() => { handleRotateKey(key.id) }}>Rotate</Dropdown.Item>
                              {(key.status !== false) && <Dropdown.Item key={1} onClick={() => handleRevokeKey(key.id)}>Revoke</Dropdown.Item>}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {/*}
                  <Button
                    className="customButton-blue"
                    onClick={() => handleRotateKey(key.id)}
                  >
                    Rotate
                  </Button>
                  {key.status !== 'Revoked' && (
                    <Button
                      className="customButton-red ml-2"
                      onClick={() => handleRevokeKey(key.id)}
                    >
                      Revoke
                    </Button>
                  )}

                  */}

                      </td>
                    </tr>
                  ))}
                  {keys.length < 3 &&
                    <div style={{ height:'60px' }}></div>
                  }
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>

      <Drawer
        isOpen={isAddKeyDrawerOpen}
        toggleSidebar={() => setIsAddKeyDrawerOpen(false)}
        name="Add API Key"
      >
        <AddApiKeyForm
        // onSubmit={handleAddKey}
        // onCancel={() => setIsAddKeyDrawerOpen(false)}
        />
      </Drawer>
    </DashLayout>
  );
};

export default Keys;
