import React, { useEffect } from 'react';
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'


//img
import headerBg from '../../assets/img/landingPage/ilustracion-home_v4.png'
import virtualApi from '../../assets/img/landingPage/02_video@2x.png'
import apilife from '../../assets/img/landingPage/apiLife.png'
import openAi from '../../assets/img/landingPage/openapi-blanco.png'
import linux from '../../assets/img/landingPage/Linux.png'
import tablet from '../../assets/img/landingPage/tablet.png'

import gradientCollab from '../../assets/img/landingPage/gradient_collab.png'
import gradientApi from '../../assets/img/landingPage/gradientApi.png'
import gradientServices from '../../assets/img/landingPage/gradientServices.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

//logos
import logo_vs from '../../assets/img/logos/AVAP_VS_white.svg'
import logo_avap from '../../assets/img/logos/AVAP_Lenguaje_white.svg'
import logo_gate from '../../assets/img/logos/AVAP_Gateway_white.svg'
import logo_cloud from '../../assets/img/logos/AVAP_Cloud_white.svg'
import logo_collab from '../../assets/img/logos/AVAP_Collaborative_white.svg'
import logo_avds from '../../assets/img/logos/AVAP_DS_white.svg'
import logo_avapConnect from '../../assets/img/landingPage/AVAP_Connect.svg'



const App = () => {
    useEffect(() => {
	
		document.body.classList.add('dark-bg');
		return () => {
			document.body.classList.remove('dark-bg');
		};

	}, []);


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-mainWrapper">
                <div className="fl-wrapper">
                    <div className="fl-container">
                        <header id="fl-header" style={{ backgroundImage: `url(${headerBg})` }}>
                            <div className="fl-content-wrapper">
                                <h1 className="fl-title-big text-center">API Lifecycle Solution</h1>
                                <p className="fl-title text-center m-0">Virtuality and AI at its core</p>
                                <p className="fl-text text-center fl-txt-container">
                                    Efficient, intelligent API management powered by next-gen technology,
                                    delivering seamless scalability and enhancedprofitability
                                    for your team and operations
                                </p>

                                <a className="glowBtn" href="#">
                                    <span className="btnInner">  Create my AVAP ID</span>
                                </a>
                            </div>
                        </header>
                    </div>
                </div>

                <div className="fl-wrapper">
                    <img src={gradientApi} alt="" className="gradient gradientapi" />
                    <div className="fl-container">
                        <section id="apiSection" className=" mt-3">

                            <h3 className="fl-title text-center">An API Virtualization Platform</h3>
                            <p className="fl-text text-center m-0">AVAP Framework is a comprehensive suite of products and services to
                                manage <br />the entire API lifecycle, from development to maintenance and deployment.</p>

                            <a href="#" className="text-center fl-link my-5"> Watch video <FontAwesomeIcon size="xs" icon={faArrowRight} /></a>
                            <img src={virtualApi} alt="" />

                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="characteristics">
                            <div className="charWrapper">
                                <div className="fl-item">
                                    Designing
                                    <div className="fl-border"></div>
                                </div>
                                <div className="fl-item">
                                    Implementation
                                    <div className="fl-border"></div>
                                </div>
                                <div className="fl-item">
                                    Deployment
                                    <div className="fl-border"></div>
                                </div>
                                <div className="fl-item">
                                    Testing
                                    <div className="fl-border"></div>
                                </div>
                                <div className="fl-item">
                                    Versioning
                                    <div className="fl-border"></div>
                                </div>

                            </div>
                        </section>

                    </div>
                </div>



                <div className="fl-wrapper">
                    <img src={gradientServices} className="gradient gradientServices" alt="" />
                    <img src={gradientApi} alt="" className="gradient gradientlife" />
                    <div className="fl-container">

                        <section id="products">
                            <h3 className="fl-title text-center">Our Products & Services</h3>

                            <div className="fl-productsWrapper">
                                <div className="fl-darkButton">
                                    <img className="logo" src={logo_avap} alt="" />
                                </div>
                                <div className="fl-darkButton">
                                    <img className="logo" src={logo_vs} alt="" />
                                </div>
                                <div className="fl-darkButton">
                                    <img className="logo" src={logo_gate} alt="" />
                                </div>
                                <div className="fl-darkButton">
                                    <img className="logo" src={logo_cloud} alt="" />
                                </div>
                                <div className="fl-darkButton">
                                    <img className="logo" src={logo_collab} alt="" />
                                </div>
                                <div className="fl-darkButton">
                                    <img className="logo" src={logo_avds} alt="" />
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="lifeCycle" className="mt-3">
                            <div className="fl-lifeWrapper">
                                <div className="fl-left">
                                    <h3 className="fl-title">Power platform for <br /> the API Lifecycle</h3>
                                    <p className="fl-text">Manage your APIs in a SaaS model —from design to <br /> testing and deployment. Powered by AVAP <br /> technology,
                                        virtuality and artificial intelligence will <br /> be in the core of every API and API product.</p>
                                    <a href="" className="fl-link"> Learn more <FontAwesomeIcon size="xs" icon={faArrowRight} /></a>
                                </div>
                                <div className="fl-right">
                                    <img className="fl-placeholder" src={apilife} alt="" />
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">

                        <section id="box">
                            <div className="fl-box">
                                <p>Official members of global tech communities, enhancing our commitment
                                    to open API standards and innovation
                                </p>
                                <div className="imgWrapper">
                                    <img src={openAi} alt="" />
                                    <img src={linux} alt="" />
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="avapCollab">
                            <img className="logoTitle" src={logo_collab} alt="" />

                            <h3 className="fl-title mt-5">
                                The first P2P network for API <br />
                                development and deployment
                            </h3>
                            <div className="imgWrapper">
                                <img src={gradientCollab} alt="" />
                                <img src={tablet} alt="" className="mt-5" />
                            </div>
                            <p className="text-center fl-c-gray">
                                Leverage a peer-to-peer platform for scalable API development and publication with AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language.
                                This network intelligently evaluates each API request for direct processing or optimal redirection to servers with faster response times,
                                enhancing virtual machine capacity across language servers through dedicated AVAP Virtual Code channels.
                            </p>

                        </section>

                    </div>
                </div>


                <div className="fl-wrapper fl-z-index-2">
                    <div className="fl-container">
                        <section id="mainFeatures">
                            <h3 className="fl-title">
                                Main Features
                            </h3>

                            <div className="fl-itemsWrapper">
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p>Free and international  Network</p>
                                        <img src={icon_free} alt="" />
                                    </div>
                                </div>
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p>Exponential Capacity</p>
                                        <img src={icon_expo} alt="" />
                                    </div>
                                </div>
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p>Dynamic thread distribution</p>
                                        <img src={icon_dyn} alt="" />
                                    </div>
                                </div>
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p>Intelligent Capacity</p>
                                        <img src={icon_expo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>


                <div className="fl-wrapper">
                    <div className="fl-container fl-z-index-2">
                        <section id="join">
                            <img className="connectLogo" src={logo_avapConnect} alt="" />
                            <h3 className="text-center fl-subtitle m-0">Join AVAP Partner Network</h3>
                            <p className="text-center fl-subtitle-blue">
                                Take advantage of the Early Access program
                            </p>
                            <p className="text-center fl-c-gray">
                                Join our program for international business partners to access a unified infrastructure, powerful sales tools, and comprehensive support. Unlock pre-sales opportunities and enhance your role within the AVAP Framework partner ecosystem, driving growth and innovation. Elevate your business today!
                            </p>

                            <button className="fl-btn-white d-table mx-auto">Join Now</button>
                        </section>
                    </div>

                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default App;
