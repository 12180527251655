import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button  } from 'react-bootstrap';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import ObexAlert from '../basicComponents/ObexAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type ModalProps = {
  showModal: boolean,
  onCloseModal: () => void,
  handleSubmit: (e) => void,
  loading: boolean,
  error: string,
  success: string,
  name_to_check: string,
  title_to_show: string
}

const ConfirmDeleteProjectModal:FunctionComponent<ModalProps> = (props:ModalProps) => {
  
  const { showModal, onCloseModal, handleSubmit, loading, error, success, name_to_check, title_to_show } = props;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [valor_a_comparar, setValorAComparar] = useState('');

  const CheckEntry = (value) =>{

    if (value==name_to_check) setSubmitDisabled(false)

  }

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={onCloseModal}
      animation={false}
      scrollable
      container={document.getElementById('dashboardWrapper')}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>Confirm delete {title_to_show}</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row>
          <Col md="8" className="text-center mx-auto">
           <Row >
            <div className='delLegend'>If there are associated staging projects they will also be eliminated. Please type <span>{name_to_check}</span> in order to confirm.</div>
            </Row>
            </Col>
          </Row>

          <Col>
            <Form>

            <Form.Row>
            <Col><span> 
              </span></Col>
            </Form.Row>
              <Form.Row >
              <Col md="6" className="mx-auto mt-3">
              <input type="text"  className="form-control" name="name" style={{ width: '100%' }} 
              onChange={(e) => {
                const { value } = e.target;
                CheckEntry(value)
              }}
              />
              </Col>
              </Form.Row>
              <Form.Row>
                <Col>

                  <ButtonSubmit 
                    loading={loading}
                    success={success!=''}
                    error={error}
                    handleSubmit={handleSubmit}
                    submitDisabled={submitDisabled}
                   
                    textButton={'Confirm Delete'}
                    color={true}/>
                </Col>
                
              </Form.Row>
              <Row>
                <Col>
                  <ObexAlert type='error' msg={error}/>
                </Col>
              </Row>
            </Form>
          </Col>

      </Modal.Body>
    </Modal>
  )
}

export default ConfirmDeleteProjectModal;