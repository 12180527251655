import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Spinner, Card } from 'react-bootstrap';
import Drawer from './Drawer';
import ApiProductForm from './newApiProductForm';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';

import orgIcon from '../../assets/img/ui_icons/cld_org-s.svg';

const ApiProducts: FunctionComponent = () => {
  const [apiProducts, setApiProducts] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const fetchApiData = async () => {
    try {
      setLoading(true);

      // Obtener API Products
      const productsResponse = await ObexRequestHandler.get(`/api/products`, {}, true);
      setApiProducts(productsResponse.data || []);

      // Obtener proyectos disponibles
      const projectsResponse = await ObexRequestHandler.get(`/projects`, {}, true);
      setAvailableProjects(projectsResponse.data || []);
      console.log('Projects Response:', projectsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <DashLayout sider={DashRouters} active={'api-products'} sublinks={[]}>
      <Row id="topHeader" className="mb-4">
        <Col md="10">
          <div className="d-flex align-items-center">
            <img src={orgIcon} alt="API Products Icon" style={{ marginRight: '16px' }} />
            <span className="cld_title">API Products</span>
          </div>
        </Col>
        <Col md="2" className="text-right">
          <Button
            style={{ width: '200px' }}
            className="customButton-blue px-4"
            onClick={toggleDrawer}
          >
            Create API Product
          </Button>
        </Col>
      </Row>

      <Row id="apiProductsList">
        {loading ? (
          <Col className="text-center">
            <Spinner animation="border" />
          </Col>
        ) : (
          apiProducts.map((product) => (
            <Col md="4" className="mb-4" key={product.id}>
              <Card style={{ height: '100%' }}>
                <Card.Body>
                  <Card.Title style={{ fontWeight: 'bold' }}>{product.name}</Card.Title>
                  <Card.Text style={{ fontSize: '14px', color: '#555' }}>
                    {product.description}
                  </Card.Text>
                  <Card.Text>
                    <strong>Rate Limit:</strong> {product.rateLimit}
                  </Card.Text>
                  <Card.Text>
                    <strong>Security:</strong> {product.security.join(', ')}
                  </Card.Text>
                  <Card.Text>
                    <strong>Type:</strong> {product.type}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>

      {/* Drawer for Creating API Products */}
      <Drawer isOpen={isDrawerOpen} toggleSidebar={toggleDrawer} name="Create API Product">
        <ApiProductForm availableProjects={availableProjects}  />
      </Drawer>
    </DashLayout>
  );
};

export default ApiProducts;
