import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Form, Alert, Spinner, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import SessionService from '../../services/SessionsService';

type AddOrganizationProps = {
  handleClose: () => void;    // Función para cerrar el Drawer
  updateTable?: () => void;    // Función para actualizar la tabla
  show: boolean;
  selectedGA: string[];
  roles: any[];
  setlistaRecuperados: any;
  listaRecuperados: any[];
};

const AddDevelopeModal: FunctionComponent<AddOrganizationProps> = ({ handleClose, updateTable, selectedGA, roles, setlistaRecuperados, listaRecuperados }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [location, setLocation] = useState('');
  const [responsible, setResponsible] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorResponsible, setErrorResponsible] = useState(false);
  const [localSelectedGA, setSelectedGA] = useState<string[]>(selectedGA);

  const mockRoles = [
    { id: '1', name: 'Owner' },
    { id: '2', name: 'Admin' },
    { id: '3', name: 'Developer' },
    { id: '4', name: 'Marketing' },
  ];


  // Funciones para manejar la entrada y validación
  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
    validateName(newCardholder);
  };

  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value;
    setCardNumber(newCardNumber);
    validateTarget(newCardNumber);
  };

  const changeLocation = (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);
    validateLocation(newLocation);
  };

  const changeResponsible = (e) => {
    const newResponsible = e.target.value;
    setResponsible(newResponsible);
    validateResponsible(newResponsible);
  };

  const validateName = (name) => {
    const nameFormat = /^[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s'-]+$/;
    setErrorNombre(!name.match(nameFormat));
  };

  const validateTarget = (description) => {
    const targetFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setErrorTarget(!description.match(targetFormat));
  };

  const validateLocation = (loc) => {
    const locationFormat = /^[a-zA-Z\s]{1,50}$/;
    setErrorLocation(!loc.match(locationFormat));
  };

  const validateResponsible = (res) => {
    const responsibleFormat = /^[a-zA-Z\s]{1,50}$/;
    setErrorResponsible(!res.match(responsibleFormat));
  };

  // Habilitar/deshabilitar el botón de envío

  const gaOptions = ['Owner', 'Admin', 'Developer', 'Marketing'];


  useEffect(() => {

    //setSelectedGA(selectedGA);

    setSubmitDisabled(
      !(
        cardholder &&
        cardNumber &&
        //listaRecuperados.length > 0 &&
        !errorNombre &&
        !errorTarget
      )
    );
  }, [cardholder, cardNumber, location, responsible, errorNombre, errorTarget, errorLocation, errorResponsible]);

  // Limpiar los campos al cerrar el Drawer
  const clearForm = () => {
    setCardNumber('');
    setCardholder('');
    setLocation('');
    setResponsible('');
    setError('');
    setSuccess('');
    setSubmitDisabled(true);
    setErrorNombre(false);
    setErrorTarget(false);
    setErrorLocation(false);
    setErrorResponsible(false);
  };

  // Llamar a `clearForm` al cerrar el Drawer
  const handleDrawerClose = () => {
    clearForm();
    handleClose();
  };

  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
      console.log("+2++")
      console.log(ga);
      console.log(localSelectedGA);
      if (cardholder && cardNumber && localSelectedGA.length > 0) setSubmitDisabled(false);
    }
  };

  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };

  // Función de envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    /*
    const result = await ObexRequestHandler.get(
      `/create_organization?organization_name=${cardholder}&organization_description=${cardNumber}&organization_location=${location}&organization_responsible=${responsible}`,
      {}, true
    );

    */

    const payload = { email: cardNumber, organization: SessionService.ORGANIZATION };
    const result = await ObexRequestHandler.post('/invite_developer', payload);

    const { success, data, message } = result;

    console.log(listaRecuperados);
    const payload2 = { roles: listaRecuperados, dev_id: data.id }

    const result2 = await ObexRequestHandler.post('/iam/assign_pending_dev_roles', payload2);


    if (success) {
      setLoading(false);
      setSuccess(data);
      setTimeout(() => {
        setSuccess('');
        if (updateTable) updateTable(); // Llama a `updateTable` solo si está definido
        handleDrawerClose(); // Cierra el Drawer y limpia el formulario
      }, 1000);
    } else {
      setError(message);
      setLoading(false);
    }
  };

  return (
    <div className="add-organization">
      <Row>
        <Col md="12" className="mx-auto">
          <Form onSubmit={handleSubmit}>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">User Name</Form.Label>
              <Form.Control
                isValid={!errorNombre && cardholder.length > 0}
                isInvalid={errorNombre}
                maxLength={18}
                required
                type="text"
                placeholder="Name"
                value={cardholder}
                onChange={changeCardholder}
              />
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">User Email</Form.Label>
              <Form.Control
                isValid={!errorTarget && cardNumber.length > 0}
                isInvalid={errorTarget}
                required
                type="text"
                maxLength={100}
                placeholder="Email"
                value={cardNumber}
                onChange={changeCardNumber}
              />
            </Form.Row>



            <Form.Group controlId="selectGA" className="mb-3">
              <Form.Label>Select Roles</Form.Label>
              <GenericSelectableList
                items={roles}
                searchKeys={['name']}
                onAdd={(role) => setSelectedGA((prev) => [...prev, role.name])}
                onRemove={(role) =>
                  setSelectedGA((prev) => prev.filter((r) => r !== role.name))
                }
                renderLabel={(role) => role.name}
                placeholder="Search roles..."
                multiOption={true} // Selección múltiple
                selectedItems={listaRecuperados}
                setSelectedItems={setlistaRecuperados}
              />
            </Form.Group>


            {/*}
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Location</Form.Label>
              <Form.Control
                isValid={!errorLocation && location.length > 0}
                isInvalid={errorLocation}
                required
                type="text"
                maxLength={50}
                placeholder="Location"
                value={location}
                onChange={changeLocation}
              />
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Responsible</Form.Label>
              <Form.Control
                isValid={!errorResponsible && responsible.length > 0}
                isInvalid={errorResponsible}
                required
                type="text"
                maxLength={50}
                placeholder="Responsible"
                value={responsible}
                onChange={changeResponsible}
              />
            </Form.Row>
            */}
            <Form.Row className="my-3">
              <Col md="6" className="">
                <Button
                  disabled={submitDisabled}
                  type="submit"
                  variant="primary" className="w-100"
                >
                  Create {success && <FontAwesomeIcon icon={faCheck} />}
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                </Button>
              </Col>
            </Form.Row>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>
    </div>
  );
};

export default AddDevelopeModal;
