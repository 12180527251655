import React, { useState, useEffect, useRef } from "react";
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

const items = [
    {
        "quarter": "Quarter 01",
        "products": [
            {
                "platform": "Avap",
                "title": "Ut labore et dolore magna aliquam erat, sed duo dolores",
                "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed miad voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
                "tags": [
                    "Launched",
                    "AI"
                ],
                "icon": "icon-ai-brunix.svg",
                "type": "yellow",
                "date": "2025-01"
            },
            {
                "platform": "AI aka Brunix",
                "title": "Ut labore et dolore magna aliquam erat, sed duo dolores",
                "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
                "tags": [
                    "Launched",
                    "AI"
                ],
                "icon": "icon-ai-brunix.svg",
                "type": "red",
                "date": "2025-01"
            },
            {
                "platform": "CLI",
                "title": "Dolor sit amet, consetetur sadipscing elitr, sed",
                "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
                "tags": [
                    "Pre-Launch",
                    "API Products"
                ],
                "icon": "icon-cli.svg",
                "type": "",
                "date": "2025-01"
            },
            {
                "platform": "Marketplace",
                "title": "Dolor sit amet, consetetur sadipscing elitr, sed",
                "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
                "tags": [
                    "Pre-Launch",
                    "API Products"
                ],
                "icon": "icon-marketplace.svg",
                "type": "red",
                "date": "2025-02"
            },
            {
                "platform": "Dev Studio",
                "title": "Ut labore et dolore magna aliquam erat, sed duo dolores",
                "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
                "tags": [
                    "Launched",
                    "AI"
                ],
                "icon": "icon-dev-studio.svg",
                "type": "green",
                "date": "2025-03"
            }
        ]
    },
    {
        "quarter": "Quarter 02",
        "products": [
            {
                "platform": "Virtual Server",
                "title": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed",
                "description": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
                "tags": [
                    "Update",
                    "Language"
                ],
                "icon": "icon-virtual-server.svg",
                "type": "red",
                "date": "2025-04"
            }
        ]
    }
]

const CloudProduct = () => {
    const [filteredQuarters, setFilteredQuarters] = useState(items);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [selectedQuarter, setSelectedQuarter] = useState(null);

    // Obtener todas las plataformas
    const allPlatforms = items.reduce((acc, q) => {
        const quarterPlatforms = q.products.map(p => p.platform);
        return acc.concat(quarterPlatforms);
    }, []);
    const uniquePlatforms = Array.from(new Set(allPlatforms));

    // Obtener quarters únicos
    const uniqueQuarters = items.map(q => q.quarter);

    const [showPlatformDropdown, setShowPlatformDropdown] = useState(false);
    const platformRef = useRef(null);
    const [showQuarterDropdown, setShowQuarterDropdown] = useState(false);
    const quarterRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (platformRef.current && !platformRef.current.contains(event.target)) {
                setShowPlatformDropdown(false);
            }
            if (quarterRef.current && !quarterRef.current.contains(event.target)) {
                setShowQuarterDropdown(false);
            }
        };

        document.body.classList.add('dark-bg');
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.body.classList.remove('dark-bg');
        };
    }, []);

    const handleSearch = () => {
        let result = [...items];

        if (selectedQuarter) {
            result = result.filter(q => q.quarter === selectedQuarter);
        }

        if (selectedPlatform) {
            result = result.map(q => ({
                ...q,
                products: q.products.filter(p => p.platform === selectedPlatform)
            })).filter(q => q.products.length > 0);
        }

        setFilteredQuarters(result);
    }



    return (
        <>
            <FlNav />
            <div id="roadmap">
                <div className="w-100 roadmapHeader">
                    <div className="fl-content">
                        <div className="fl-title">Product Roadmap</div>
                        <div className="fl-subtitle">What are the latest developments at AVAP?</div>
                        <div className="fl-text">Stay up to date with what we’re working on, planned releases and our newest features.</div>
                    </div>

                    <div className="search">
                        <div className="instructions">
                            Selecciona uno o más filtros
                        </div>
                        <div className="fl-selectsWrapper">
                            {/* Select de producto */}
                            <div className="fl-customSelectWrapper productselect" ref={platformRef}>
                                <div 
                                    className="fl-customSelect" 
                                    onClick={() => setShowPlatformDropdown(!showPlatformDropdown)}
                                >
                                    <span>{selectedPlatform ? selectedPlatform : "Selecciona un producto"}</span>
                                    <div className="fl-arrow"></div>
                                </div>
                                {showPlatformDropdown && (
                                    <div className="fl-customOptions">
                                        {uniquePlatforms.map((plat, i) => (
                                            <div 
                                                key={i} 
                                                className="fl-customOption" 
                                                onClick={() => {
                                                    setSelectedPlatform(plat);
                                                    setShowPlatformDropdown(false);
                                                }}
                                            >
                                                {plat}
                                            </div>
                                        ))}
                                        <div 
                                            className="fl-customOption" 
                                            onClick={() => {
                                                setSelectedPlatform(null);
                                                setShowPlatformDropdown(false);
                                            }}
                                        >
                                            Clean filters
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Select de Quarter */}
                            <div className="fl-customSelectWrapper " ref={quarterRef}>
                                <div 
                                    className="fl-customSelect" 
                                    onClick={() => setShowQuarterDropdown(!showQuarterDropdown)}
                                >
                                    <span>{selectedQuarter ? selectedQuarter : "Selecciona un Quarter"}</span>
                                    <div className="fl-arrow"></div>
                                </div>
                                {showQuarterDropdown && (
                                    <div className="fl-customOptions">
                                        {uniqueQuarters.map((q, i) => (
                                            <div 
                                                key={i} 
                                                className="fl-customOption" 
                                                onClick={() => {
                                                    setSelectedQuarter(q);
                                                    setShowQuarterDropdown(false);
                                                }}
                                            >
                                                {q}
                                            </div>
                                        ))}
                                        <div 
                                            className="fl-customOption" 
                                            onClick={() => {
                                                setSelectedQuarter(null);
                                                setShowQuarterDropdown(false);
                                            }}
                                        >
                                            Clean filters
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Botón de búsqueda */}
                            <button className="fl-searchButton" onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="timeline">
                                {filteredQuarters.map((quarterData, qIndex) => {
                                    
                                    const year = quarterData.products.length > 0 
                                        ? quarterData.products[0].date.substring(0,4) 
                                        : 'N/A';

                                    return (
                                        <React.Fragment key={qIndex}>
                                           
                                            <div className="quarterTitle">
                                                {quarterData.quarter} - {year}
                                            </div>
                                            {quarterData.products.map((item, index) => (
                                                <div key={index} className="fl-timelineWrapper">
                                                    {/* Fecha */}
                                                    <div className="date">
                                                        <div className="dateWrapper">
                                                            {item.date}
                                                        </div>
                                                        <div className="fl-border"></div>
                                                    </div>

                                                    {/* Card */}
                                                    <div className="fl-timelineCard">
                                                        <div className="fl-cardContent">
                                                            {/* Producto */}
                                                            <div className={`product ${item.type}`}>{item.platform}</div>

                                                            {/* Título */}
                                                            <div className="fl-subtitle">{item.title}</div>

                                                            {/* Descripción */}
                                                            <div className="fl-text">{item.description}</div>

                                                            {/* Etiquetas */}
                                                            <div className="labels">
                                                                {item.tags.map((tag, idx) => (
                                                                    <div key={idx} className="label">{tag}</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FlFooter />
        </>
    );
};

export default CloudProduct;
