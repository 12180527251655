import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Form, Table, Spinner, Image, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddOrganizationModal from '../Modals/addOrganizationModal';
import EditOrganizationModal from '../Modals/editOrganizationModal';
import SessionService from '../../services/SessionsService';
import AddOrganizationSlots from '../Modals/addOrganizationSlots';
import Drawer from './Drawer';

import orgIcon from '../../assets/img/ui_icons/cld_org-s.svg'

import Logo from '../../assets/img/ilustracion_noInfo2.png';
import editicon from '../../assets/img/ui_icons/cld_edit_table.svg';
import deleteIcon from '../../assets/img/ui_icons/cld_delete.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

//Note Cloud: Organization view

interface Region {
  id: number;
  region: string;
  server: string;
  code: string;
}

interface Developers {
  dev_id: number;
  email: string;

}

type IncomeProps = {};

const Organizations: FunctionComponent<IncomeProps> = ({ }) => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filtro, setFilter] = useState('');
  const [selected_org, setSelectedOrg] = useState(0);
  const [selected_org_name, setSelectedOrgName] = useState('');
  const [selected_org_description, setSelectedOrgDescription] = useState('');
  const [selected_org_region_id, setSelectedOrgRegionId] = useState(0);
  const [selected_org_region_name, setSelectedOrgRegionName] = useState('');
  const [selected_org_responsible_id, setSelectedOrgResponsibleId] = useState(0);
  const [selected_org_responsible_email, setSelectedOrgResponsibleName] = useState('');
  const [selected_org_devs, setSelectedOrgDevs] = useState<Developers[]>([]);

  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isAddOrgDrawerOpen, setIsAddOrgDrawerOpen] = useState(false);
  const [addingOrg, setaddingOrg] = useState(false);
  const [developerNames, setDeveloperNames] = useState({});
  const [regions, setRegions] = useState<Region[]>([]);


  const toggleEditDrawer = async (org_id) => {
    getProjects('');
    await fetchOrgDevs(org_id);
    setIsEditDrawerOpen(!isEditDrawerOpen);
  };
  const toggleAddOrgDrawer = async (org_id) => {
    await fetchOrgDevs(org_id);
    setIsAddOrgDrawerOpen(!isAddOrgDrawerOpen);
  };

  const changeFilter = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
  };

  const handleOrg = () => {
    setaddingOrg(!addingOrg);
    document.getElementById('buscador')?.focus();
    getProjects('');
  };

  const handleModal = () => {
    getProjects('');
    setShowModal(!showModal);
    document.getElementById('buscador')?.focus();
    setTimeout(() => {
      SessionService.dispatchOrganizationChangedEvent();
    }, 1000);
  };

  // Function to open the Edit Drawer with selected organization details
  const handleModal2 = (orgId: number, orgName: string, orgDescription: string, region_id: number, region_name: string, developerMail: string, developerID: number) => {
    setSelectedOrg(orgId);
    setSelectedOrgName(orgName);
    setSelectedOrgDescription(orgDescription);
    setSelectedOrgRegionId(region_id);
    setSelectedOrgRegionName(region_name);
    setSelectedOrgResponsibleId(developerID);
    setSelectedOrgResponsibleName(developerMail)
    //console.log(region_name)
    //console.log(region_id)
    //console.log(developerMail)
    //console.log(developerID)
    toggleEditDrawer(orgId);
  };


  const fetchOrgDevs = async (organization) =>{

    const devs = (await ObexRequestHandler.get(`/organization_devs?organization=${organization}`, {}, true)).data || [];
      //console.log(devs);
      setSelectedOrgDevs(devs);
  }

  const fetchRegions = async () => {
    try {
     const response = await ObexRequestHandler.get('/servers_regions');

        if (response.data) {
            const regions_ = response.data;
            setRegions(regions_);
        } else {
            console.error('Error in the request:', response.data.error);
        }
    } catch (error) {
        console.error('Error at fetching regions', error);

    }
};



  const getProjects = async (filtro: string) => {
    try {
      setLoading(true);
      setProjects([]);
      const projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
      //console.log('Fetched Projects:', projects);
      setCurrentOrganizations(projects['result'].length);
      setMaxOrganizations(projects['max']);
  
      const filter_projects = projects['result'].filter(element =>
        element.name.toLowerCase().includes(filtro.toLowerCase())
      );
      setProjects(filtro === '' ? projects['result'] : filter_projects);
  
      // Obtener detalles de los desarrolladores para cada proyecto
      for (const project of projects.result) {
        if (project.developers > 0) {
          try {
            const developerDetails = await ObexRequestHandler.get(`/organizations/${project.id}/developers`);//revisar si hay un endpoint
            console.log(`Developer for project ${project.id}:`, developerDetails.data || 'No developer data');
          } catch (error) {
            console.error(`Error fetching developer for project ${project.id}:`, error);
          }
        }
      }
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    } finally {
      setLoading(false);
      document.getElementById('buscador')?.focus();
    }
  };
  

  const fetchDevelopers = async (projects) => {
    const devNames = {};
    for (const project of projects) {
      if (project.developers > 0) {
        try {
          const response = await ObexRequestHandler.get(`/organizations/${project.id}/developers`);
          devNames[project.id] = response.data?.[0]?.name || 'Unknown';
        } catch (error) {
          console.error(`Error fetching developer for project ${project.id}:`, error);
          devNames[project.id] = 'Unknown';
        }
      }
    }
    return devNames;
  };
  

  const handleDelete = async (orgId) => {
    if (window.confirm('Are you sure you want to delete this organization?')) {
      try {
        await ObexRequestHandler.delete(`/organizations/${orgId}`); 
        alert('Organization deleted successfully');
        getProjects(''); // Actualizar la lista después de eliminar
      } catch (error) {
        console.error('Error deleting organization:', error);
        alert('Failed to delete the organization');
      }
    }
  };
  

  useEffect(() => {
    getProjects('');
    fetchRegions();
    setSelectedOrg(SessionService.ORGANIZATION)
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'organizations'} sublinks={[]}>

          <Row id='topHeader'>
            <Col md="10" >
              <div className='d-flex flex-column'>
                <div className="cld_wrapperTitle">
                  <img src={orgIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">
                  IAM <span style={{color:'#949498', fontSize:'14px'}}> /  Organizations</span>
                  </span>
                </div>

                {/* <span className="cld_legend w-100">
                  View and manage a list of organizations you're associated with.
                </span> */}
              </div>
            </Col>
            <Col md="2" className='align-content-center'>
              {currentOrganizations < maxOrganizations ? (
                <Button
                  style={{ width: '225px' }}
                  className="customButton-blue px-4 float-right   resBtn"
                  onClick={() => {
                    fetchOrgDevs(selected_org);
                    setIsAddOrgDrawerOpen(true)
                    }
                  } // Abre el modal para añadir una nueva organización
                >
                  Create Organization
                </Button>
              ) : (
                <Button
                  style={{ width: '225px' }}
                  className="customButton-blue px-4 float-right  resBtn"
                  onClick={() => setaddingOrg(true)} // Abre `AddOrganizationSlots` cuando se alcanza el límite
                >
                 Update Plan
                </Button>
              )}
            </Col>
            <Col>
              <div className="customBorder"></div>
            </Col>
          </Row>

          {/* <Row id="searchContent" className='mt-5'>
            <div className="col">
              <Form.Row >
                <Col>
                  <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Organization name"
                    onChange={changeFilter} />
                </Col>

                <Col md={3}>
                  <Button className=" customButton-blue w-100 px-4"
                    onClick={() => getProjects(filtro)}><FontAwesomeIcon icon={faSearch} size="1x" /></Button>
                </Col>
              </Form.Row>
            </div>
          </Row> */}

          <Row id='table' className='mt-5'>
            <Col>
              {addingOrg && (
                <AddOrganizationSlots shown={addingOrg} onCloseAction={handleOrg} onSuccess={handleOrg} MaxDBSize={1} ActualDBSize={0} />
              )}
              {loading ? (
                <Row style={{ textAlign: 'center' }}>
                  <Col>
                    <Spinner animation="border" style={{ marginLeft: '8px' }} />
                  </Col>
                </Row>
              ) : (
                <div className='cld_borderWrapper'>
                  <span className='cld_sectionTitle'>Organizations</span>

                  <div className="searchContainer">
                    <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Organization name"
                      onChange={changeFilter} />
                    <Button className="customButton-transparent px-4"
                      onClick={() => getProjects(filtro)}><FontAwesomeIcon icon={faSearch} size="1x" /></Button>
                  </div>



                  {/* <Table className='cld_tableWrapper' responsive>
                    <tbody>
                      <tr>
                        <th className='textBold'>Name</th>
                        <th className='textBold'>Description</th>
                        <th className='textBold'># Of Developers</th>
                        <th className='textBold'>Action</th>
                      </tr>
                      {projects.map((project) => (
                        <tr className='tableRow' key={project.id}>
                          <td className='project'>{project.name}</td>
                          <td className='desc'>{project.description}</td>
                          <td className='devs'><div className="devsItem">{project.developers}</div></td>
                          <td className='text-right'>
                            <div className="actionsWrapper">
                              <a className="edit-link" onClick={() => handleModal2(project.id, project.name, project.description)}>
                                <span className="editIcon" style={{ backgroundImage: `url(${editicon})` }}></span>
                                Edit
                              </a>

                      
                              {project.developers === 0 && (
                                <button
                                  className="customButton-red ml-2"
                                  onClick={() => handleDelete(project.id)}
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
 */}


                  <Table className='cld_tableWrapper' responsive>
                    <tbody>
                      <tr className='tableRow'>
                        <th style={{ width:'30%' }} className='textBold'>Name</th>
                        <th style={{ width:'30%' }} className='textBold'>Location</th>
                        <th style={{ width:'30%' }} className='textBold'>Responsible</th>
                        <th className='textBold'># Of Members</th>
                        <th style={{ width:'5%', textAlign:'end' }} className='textBold'>Actions</th>
                      </tr>
                      {projects.map((project) => (
                        <tr className='tableRow' key={project.id}>
                          <td className='project'>{project.name || 'Not specified'}</td>
                          <td className='location'>{project.region_name || 'Not specified'}</td>
                          <td className='responsible'>{project.responsible_email || 'Not specified'}</td>
                          <td className='devs'>
                            <div style={{ backgroundColor: 'var(--color-background-from)' }} className="devsItem">{project.developers || 0}</div>
                          </td>
                          <td className='text-right'>
                            <div className="actionsWrapper">
                              {/*<a className="edit-link" onClick={() => handleModal2(project.id, project.name, project.description)}>
                                <span className="editIcon" style={{ backgroundImage: `url(${editicon})` }}></span>
                                Edit
                              </a>*/}

                              <Dropdown className='my-auto' style={{ marginRight:'20px' }}>
                                  <Dropdown.Toggle className="organizations" >
                                    <FontAwesomeIcon icon={faEllipsisV}   />
                                  </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item key={1} onClick={ () => handleModal2(project.id, project.name, project.description, project.region_id, project.region_name, project.responsible_email, project.developer_id) }>Edit</Dropdown.Item>
                                      {(!project.developers || project.developers === 0) && <Dropdown.Item key={1} onClick={ () => handleDelete(project.id) }>Delete</Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>


                              {/* Mostrar el botón de eliminar solo si no hay miembros */}
                              {(!project.developers || project.developers === 0) && (
                                /*<a
                                
                                  className="edit-link ml-2"
                                  onClick={() => handleDelete(project.id)}
                                >
                                   <span className="editIcon" style={{ backgroundImage: `url(${deleteIcon})` }}></span>
                                  Delete
                                </a>*/
/*
                                <Dropdown className='my-auto' style={{marginRight:'20px'}}>
                                <Dropdown.Toggle className="organizations" >
                                  <FontAwesomeIcon icon={faEllipsisV}   />
                                </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item key={1} onClick={ () => setIsAddOrgDrawerOpen(true) }>Edit</Dropdown.Item>
                                    <Dropdown.Item key={1} onClick={ () => setIsAddOrgDrawerOpen(true) }>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                              </Dropdown>
*/<></>

                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Col>
          </Row>

          {projects.length === 0 && !loading && (
            <div>
              <Row className="justify-content-center my-4">
                <Col xs="6" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Image style={{ width: '50%', marginTop: '60px' }} src={Logo} fluid />
                </Col>
              </Row>
              <Col style={{ textAlign: 'center', marginBottom: '60px' }}>
                <Row><Col><h5>You don’t have data to show</h5></Col></Row>
                <Row><Col>When you have data to show, you’ll find here.</Col></Row>
              </Col>
            </div>
          )}

          {/* Drawer for Editing Organization */}
          <Drawer
            isOpen={isEditDrawerOpen}
            toggleSidebar={()=>toggleEditDrawer(selected_org)}
            name="Edit Organization"
          >
            <EditOrganizationModal
              organization={selected_org}
              organization_name={selected_org_name}
              organization_description={selected_org_description}
              organization_location_name={selected_org_region_name}
              organization_location={{ region_id: selected_org_region_id, region_name: selected_org_region_name }}
              organization_responsible={selected_org_responsible_email}
              handleClose={()=>toggleEditDrawer(selected_org)}
              regions={regions}
              organization_devs={selected_org_devs}
              organization_responsible_id={selected_org_responsible_id}
              organization_location_id={selected_org_region_id}
            />
          </Drawer>


          <Drawer
            isOpen={isAddOrgDrawerOpen}
            toggleSidebar={()=>toggleAddOrgDrawer(selected_org)}
            name="Add Organization"
          >

            <AddOrganizationModal
              handleClose={()=>toggleAddOrgDrawer(selected_org)} // Cierra el Drawer
              updateTable={() => getProjects('')} // Actualiza la tabla tras la creación
              show
              regions={regions}
              organization_devs={selected_org_devs}
            />


          </Drawer>



        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
};

export default Organizations;
