import React, { useState } from "react";
import { Accordion, Card } from 'react-bootstrap';

import icn_item from '../../../assets/img/ui_icons/cld_learn.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faGraduationCap, faSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquarespace } from "@fortawesome/free-brands-svg-icons";

function LearningDrawer() {
    const [openIndex, setOpenIndex] = useState(null); // Estado para almacenar el índice del Accordion abierto

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Cambia el estado para abrir/cerrar el índice específico
    };

    return (
        <div
        style={{
            maxWidth: '100%',
            marginLeft:'auto',
            minWidth: '272px',
            height:'26.8vw',
            //marginRight:'-1vw',
            minHeight:'400px'

        }}
        id='w_learn' className="item h-type-4">
            <div className="title">
                <span><img style={{ height:'15px' }} src={icn_item} alt="" /></span>
                <p>Learning</p>
            </div>
            <div className="flex-full mt-3 accordionWrapper">
                <Accordion className="customAccordion">
                 
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(0)}
                        >
                            <div>
                                <span style={{marginRight:'10px'}}><FontAwesomeIcon icon={faSquare}/></span>
                                <span>What is topic 01?</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 0 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>This is the content for topic 01. You can add any text or elements here.</Card.Body>
                        </Accordion.Collapse>
          
                </Accordion>

                <Accordion className="customAccordion">
             
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="1"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(1)}
                        >
                            <div>
                            <span style={{marginRight:'10px'}}><FontAwesomeIcon icon={faSquare}/></span>
                                <span>How my organization works?</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 1 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>This is the content for how my organization works.</Card.Body>
                        </Accordion.Collapse>
   
                </Accordion>

                <Accordion className="customAccordion">
      
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="2"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(2)}
                        >
                            <div>
                            <span style={{marginRight:'10px'}}><FontAwesomeIcon icon={faSquare}/></span>
                                <span>How to manage my dev team?</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 2 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>This is the content for managing your dev team.</Card.Body>
                        </Accordion.Collapse>

                </Accordion>

                <Accordion className="customAccordion">
    
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey="3"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(3)}
                        >
                            <div>
                            <span style={{marginRight:'10px'}}><FontAwesomeIcon icon={faSquare}/></span>
                                <span>Publish an API?</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 3 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                            </Card.Body>
                        </Accordion.Collapse>
                   
                </Accordion>
            </div>
            <div className="bottom"></div>
        </div>
    );
}

export default LearningDrawer;
