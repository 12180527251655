import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

import SetupOrganization from './components/forms/setupOrganization';
import PricingTable from './components/pricingTable';
import SelectablePricingTable from './components/SelectablepricingTable';
import Drawer from './components/Drawer';

//forms
import F_CreateAPi from './components/forms/createApi';
import F_ComplexApi from './components/forms/complexApiForm';
import F_Addendpoint from './components/forms/addEndpoint';
import F_APiOwnership from './components/forms/ApiOwnershipForm';
import AddWebhookForm from './components/forms/AddWebhookForm';
import AddEditUserForm from './components/forms/AddEditUserForm';
import ApiAccessApprovalForm from './components/forms/ApiAccessApprovalForm';
import AddApiKeyForm from './components/forms/addKey';
import AddPolicyForm from './components/forms/AddPolicyForm';
import AddCertificateForm from './components/forms/AddCertificateForm';
import AddApiToCatalogueForm from './components/forms/AddApiToCatalogueForm';
import CreateProductVersionForm from './components/forms/CreateProductVersionForm';
import NewDocumentationPageForm from './components/forms/NewDocumentationPageForm';
import CreateServiceForm from './components/forms/CreateServiceForm';
import CreateTeamForm from './components/forms/addTeam';
import InviteUserForm from './components/forms/InviteUserForm';
import VersionEditForm from './components/forms/version';
import GenerateAccessTokenForm from './components/forms/AccesTokenForm';
import EditProfileForm from './components/forms/EditProfileForm';
import SessionService from '../../services/SessionsService';






function Dashboard() {
    const initialTheme = localStorage.getItem('darkMode') === 'true' || localStorage.getItem('darkMode') === null;
    const [isCollapsed, setIsCollapsed] = useState(SessionService.ISCOLLAPSED);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(initialTheme);

    // Estado para múltiples drawers
    const [drawers, setDrawers] = useState({
        createApiDrawer: false,
        complexApiDrawer: false,
        endpointDrawer: false,
        APiOwnership: false,
        AddWebhookForm: false,
        AddEditUserForm: false,
        ApiAccessApprovalForm: false,
        AddApiKeyForm: false,
        AddPolicyForm: false,
        AddCertificateForm: false,
        AddApiToCatalogueForm: false,
        CreateProductVersionForm: false,
        NewDocumentationPageForm: false,
        CreateServiceForm: false,
        CreateTeamForm: false, 
        InviteUserForm: false,
        VersionEditForm: false,
        GenerateAccessTokenForm: false,
        EditProfileForm: false,



    });

    useEffect(() => {
        const dashboardWrapper = document.getElementById('dashboardWrapper');
        if (dashboardWrapper) {
            if (isDarkMode) {
                dashboardWrapper.classList.add('dark-mode');
            } else {
                dashboardWrapper.classList.remove('dark-mode');
            }
        }
    }, [isDarkMode]);

    const toggleSidebar = () => {
        SessionService.ISCOLLAPSED = !isCollapsed;
        setIsCollapsed(!isCollapsed);
    };

    const toggleDrawer = (drawerName) => {
        setDrawers((prevDrawers) => ({
            ...prevDrawers,
            [drawerName]: !prevDrawers[drawerName],
        }));
    };

    const toggleMobileSidebar = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', String(newMode));
            return newMode;
        });
    };

    return (
        <div className="d-flex dark-mode" id="dashboardWrapper">
            <Sidebar isCollapsed={isCollapsed} isMobileOpen={isMobileOpen} />

            <div id="page-content-wrapper" className="w-100">
                <Topbar
                    toggleSidebar={toggleSidebar}
                    toggleMobileSidebar={toggleMobileSidebar}
                    toggleTheme={toggleTheme}
                    isCollapsed={isCollapsed}
                    isDarkMode={isDarkMode}
                />
                <div id='content' className="container-fluid mt-0">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='title margin-y-24px'><img src="" alt="" /><span>Components and Forms</span></h1>
                            <div className='customBorder' />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-12">
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("createApiDrawer")}
                            >
                                Create API
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2 '
                                onClick={() => toggleDrawer("complexApiDrawer")}
                            >
                                Complex API
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("endpointDrawer")}
                            >
                                Add end point
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("APiOwnership")}
                            >
                                APiOwnership
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("AddEditUserForm")}
                            >
                                AddEditUserForm
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("ApiAccessApprovalForm")}
                            >
                                ApiAccessApprovalForm
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("AddApiKeyForm")}
                            >
                                AddApiKeyForm
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("AddPolicyForm")}
                            >
                                AddPolicyForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("AddCertificateForm")}
                            >
                                AddCertificateForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("AddApiToCatalogueForm")}
                            >
                                AddApiToCatalogueForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("CreateProductVersionForm")}
                            >
                                CreateProductVersionForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("NewDocumentationPageForm")}
                            >
                                NewDocumentationPageForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("CreateServiceForm")}
                            >
                                CreateServiceForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("CreateTeamForm")}
                            >
                                CreateTeamForm
                            </button>
                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("InviteUserForm")}
                            >
                                InviteUserForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("VersionEditForm")}
                            >
                                VersionEditForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("GenerateAccessTokenForm")}
                            >
                                GenerateAccessTokenForm
                            </button>

                            <button
                                className='cld-primary-button mr-2 my-2'
                                onClick={() => toggleDrawer("EditProfileForm")}
                            >
                                EditProfileForm
                            </button>







                            <Drawer
                                isOpen={drawers.createApiDrawer}
                                toggleSidebar={() => toggleDrawer("createApiDrawer")}
                                name="Create API"
                            >
                                <F_CreateAPi />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.complexApiDrawer}
                                toggleSidebar={() => toggleDrawer("complexApiDrawer")}
                                name="Complex API"
                            >
                                <F_ComplexApi />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.endpointDrawer}
                                toggleSidebar={() => toggleDrawer("endpointDrawer")}
                                name="Create Endpoint"
                            >
                                <F_Addendpoint />
                            </Drawer>
                            <Drawer
                                isOpen={drawers.APiOwnership}
                                toggleSidebar={() => toggleDrawer("APiOwnership")}
                                name="Create Endpoint"
                            >
                                <F_APiOwnership />
                            </Drawer>
                            <Drawer
                                isOpen={drawers.AddWebhookForm}
                                toggleSidebar={() => toggleDrawer("AddWebhookForm")}
                                name="Add Webhook"
                            >
                                <AddWebhookForm />
                            </Drawer>
                            <Drawer
                                isOpen={drawers.AddEditUserForm}
                                toggleSidebar={() => toggleDrawer("AddEditUserForm")}
                                name="Add User"
                            >
                                <AddEditUserForm />
                            </Drawer>
                            <Drawer
                                isOpen={drawers.ApiAccessApprovalForm}
                                toggleSidebar={() => toggleDrawer("ApiAccessApprovalForm")}
                                name="Api Access Approval"
                            >
                                <ApiAccessApprovalForm />
                            </Drawer>


                            <Drawer
                                isOpen={drawers.AddApiKeyForm}
                                toggleSidebar={() => toggleDrawer("AddApiKeyForm")}
                                name="Add Api Key"
                            >
                                <AddApiKeyForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.AddPolicyForm}
                                toggleSidebar={() => toggleDrawer("AddPolicyForm")}
                                name="Add Policy"
                            >
                                <AddPolicyForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.AddCertificateForm}
                                toggleSidebar={() => toggleDrawer("AddCertificateForm")}
                                name="Add Certificate"
                            >
                                <AddCertificateForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.AddApiToCatalogueForm}
                                toggleSidebar={() => toggleDrawer("AddApiToCatalogueForm")}
                                name="Add Api To Catalogue"
                            >
                                <AddApiToCatalogueForm />
                            </Drawer>


                            <Drawer
                                isOpen={drawers.CreateProductVersionForm}
                                toggleSidebar={() => toggleDrawer("CreateProductVersionForm")}
                                name="Create Product Version"
                            >
                                <CreateProductVersionForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.NewDocumentationPageForm}
                                toggleSidebar={() => toggleDrawer("NewDocumentationPageForm")}
                                name="New Documentation Page"
                            >
                                <NewDocumentationPageForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.CreateServiceForm}
                                toggleSidebar={() => toggleDrawer("CreateServiceForm")}
                                name="Create Service"
                            >
                                <CreateServiceForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.CreateTeamForm}
                                toggleSidebar={() => toggleDrawer("CreateTeamForm")}
                                name="Create Team"
                            >
                                <CreateTeamForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.InviteUserForm}
                                toggleSidebar={() => toggleDrawer("InviteUserForm")}
                                name="Invite User"
                            >
                                <InviteUserForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.VersionEditForm}
                                toggleSidebar={() => toggleDrawer("VersionEditForm")}
                                name="version Edit"
                            >
                                <VersionEditForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.GenerateAccessTokenForm}
                                toggleSidebar={() => toggleDrawer("GenerateAccessTokenForm")}
                                name="Generate Access Token"
                            >
                                <GenerateAccessTokenForm />
                            </Drawer>

                            <Drawer
                                isOpen={drawers.EditProfileForm}
                                toggleSidebar={() => toggleDrawer("EditProfileForm")}
                                name="Generate Access Token"
                            >
                                <EditProfileForm />
                            </Drawer>















                        </div>
                    </div>

                    {/* Other content */}

                    <div className="row mt-5">
                        <div className="col-12">
                            <span className='cld-subsection'>Pricing Table</span>
                            <div className='customBorder my-3' />
                        </div>
                        <div className="col-md-6">
                            <PricingTable />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <span className='cld-subsection'>Setup Organization</span>
                            <div className='customBorder my-3' />
                        </div>
                        <div className="col-md-6">
                            <SetupOrganization />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <span className='cld-subsection'>Selectable Pricing Table</span>
                            <div className='customBorder my-3' />
                        </div>
                        <div className="col-md-7">
                            <SelectablePricingTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
