import { Container, Row, Col } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from '../baseLayout/HeaderMenu';
import DashboardSideBar from './DashboardSidebar';
import Footer from './FooterMenu';

type BaseProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>,
  projects?: Array<any>,
  children?: any
};

const BaseLayout: FunctionComponent<BaseProps> = (props: BaseProps) => {
  const { sider, children, active, sublinks } = props;

  const valuesSideBarClient = [
    "Dashboard",
    "Projects",
    "Requests",
    "FX Exchange",
    "Balance",
    "Income",
    "Marketplace",
    "Developer Teams",
    "Organizations",
    "Developers",
    "Subscription",
    "Settings"
  ];

  const [isDashboardBlue, setIsDashboardBlue] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false); // Estado para el modo oscuro

  const groupSider = () => {
    const groupTitles = sider.menus.map(item => item.title);
    const equal = arraysAreEqual(valuesSideBarClient, groupTitles);
    if (equal) {
      setIsDashboardBlue(true);
    }
  };

  function arraysAreEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  // Cargar el tema desde localStorage cuando el componente se monta
  useEffect(() => {
    const savedTheme = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedTheme); // Inicializa el tema de acuerdo a lo que esté guardado
  }, []);

  // Actualizar la clase del body y guardar el tema en localStorage cuando isDarkMode cambie
  useEffect(() => {
    document.body.classList.add('cloudDashboard');
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
      localStorage.setItem('darkMode', 'true'); // Guarda el modo oscuro en localStorage
    } else {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('darkMode', 'false'); // Guarda el modo claro en localStorage
    }
  }, [isDarkMode]);

  return (
    <>
      {
        isDashboardBlue && (
          <Container fluid className='px-0' style={{ paddingLeft: '0px !important', paddingRight: '0px !important', marginRight: '9rem !important' }}>
            <HeaderMenu isDashboardBlue={isDashboardBlue} />
            <Row id="content-page" className='marginTopDashboardBlue'>
              <DashboardSideBar isDarkMode={isDarkMode} sider={sider} active={active} sublinks={sublinks} />
              <Col id="content" className={`d-flex flex-column min-vh-100 ${isDashboardBlue ? 'marginContentAvapCloud' : ''}`}>
                <Row className="pt-4 pl-4 pr-4 mr-0 ml-0" style={{ marginRight: '9rem' }}>
                  <Col>
                    {children}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Footer />
          </Container>
        )
      }

      {
        !isDashboardBlue && (
          <div className="" id="dashboardWrapper">
            
            {/*}
            <button onClick={() => setIsDarkMode(!isDarkMode)} className="toggle-dark-mode">
              <span>Theme</span>
              {isDarkMode ? "Dark" : "Light"}
            </button> */}

            {/* Page Content */}
            <HeaderMenu isDashboardBlue={true} />
            <div id="page-content-wrapper" className="w-100">
              {/* Topbar */}

              <DashboardSideBar isDarkMode={isDarkMode} sider={sider} active={active} sublinks={sublinks} />
              <div id='content' className="container-fluid mt-0 p-0">
             

                <div className="scrollContainer">
                {children}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default BaseLayout;
