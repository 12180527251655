import React, { useState, useEffect } from "react";
import { Table, Row, Col, Form, Spinner, Pagination, Button } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import devIcon from '../../assets/img/ui_icons/cld_org-s.svg'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";


const PermissionsView = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [permissionsPerPage] = useState(10);

  // Simulación de carga de permisos
  const fetchPermissions = async () => {
    setLoading(true);
    const fakePermissions = [
      { id: 1, name: "storage.objects.create", description: "Create objects in buckets", service: "Cloud Storage" },
      { id: 2, name: "storage.objects.delete", description: "Delete objects in buckets", service: "Cloud Storage" },
      { id: 3, name: "compute.instances.start", description: "Start virtual machines", service: "Compute Engine" },
      { id: 4, name: "compute.instances.stop", description: "Stop virtual machines", service: "Compute Engine" },
      { id: 5, name: "bigquery.datasets.create", description: "Create datasets in BigQuery", service: "BigQuery" },
      { id: 6, name: "bigquery.tables.delete", description: "Delete tables in BigQuery", service: "BigQuery" },
      { id: 7, name: "pubsub.topics.publish", description: "Publish messages to topics", service: "Pub/Sub" },
      { id: 8, name: "pubsub.topics.subscribe", description: "Subscribe to topics", service: "Pub/Sub" },
      { id: 9, name: "iam.roles.get", description: "Retrieve details of roles", service: "IAM" },
      { id: 10, name: "iam.roles.create", description: "Create new roles", service: "IAM" },
      // Agregar más permisos aquí...
    ];
    setTimeout(() => {
      setPermissions(fakePermissions);
      setLoading(false);
    }, 1000);
  };


  const getPermits = async (filtro) => {
    try {

      //setloadDevs(true);

      setPermissions([]);
      
      const permits = (
        await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];

        const filter_permits = permits.filter(element =>
          element.permit_code.toLowerCase().includes(filtro.toLowerCase())
        );
        setPermissions(filtro === '' ? permits : filter_permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      

    }
    
  }


  useEffect(() => {
    getPermits('')
    //fetchPermissions();
  }, []);

  // Manejo de búsqueda
  const handleSearch = (e) => setSearch(e.target.value);

  const filteredPermissions = permissions.filter(
    (permission) =>
      permission.permit_code.toLowerCase().includes(search.toLowerCase()) ||
      permission.permit_description.toLowerCase().includes(search.toLowerCase()) ||
      permission.permit_service.toLowerCase().includes(search.toLowerCase())
  );

  // Paginación
  const indexOfLastPermission = currentPage * permissionsPerPage;
  const indexOfFirstPermission = indexOfLastPermission - permissionsPerPage;
  const currentPermissions = filteredPermissions.slice(indexOfFirstPermission, indexOfLastPermission);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <DashLayout sider={DashRouters} active={"permissions"} sublinks={[]}>
      <Row id='topHeader'>
            <Col md='12' >
              <div className='d-flex flex-column'>
                <div className="cld_wrapperTitle">
                  <img src={devIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">
                  IAM <span style={{color:'#949498', fontSize:'14px'}}> /  Permissions Reference</span>
                    </span>
                </div>

              </div>
            </Col>
            <Col>
              <div className="customBorder"></div>
            </Col>
          </Row>



      {loading ? (
        <Spinner animation="border" />
      ) : (

        <><div className="mt-5"></div>
        <div className="cld_borderWrapper">

         
        <span className='cld_sectionTitle'>Permits</span>

        <div className="searchContainer">
              
                <Form.Control id='buscador'
                  type="text"
                  placeholder="Search permissions..."
                  value={search}
                  onChange={handleSearch} />
                  <Button className="customButton-transparent px-4"
                      onClick={handleSearch}><FontAwesomeIcon icon={faSearch} size="1x" />
                    </Button>
             
            </div>


            <Table className="cld_tableWrapper" responsive>
              <thead>
                <tr className="headerRow">
                  <th className="textBold">Permission</th>
                  <th className="textBold">Description</th>
                  <th className="textBold">Service</th>
                </tr>
              </thead>
              <tbody>
                {currentPermissions.map((permission) => (
                  <tr className="tableRow" key={permission.id}>
                    <td>{permission.permit_code}</td>
                    <td>{permission.permit_description}</td>
                    <td>{permission.permit_service}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Pagination className="mt-3">
              {filteredPermissions.length > 0 &&
                Array.from(
                  { length: Math.max(1, Math.ceil(filteredPermissions.length / permissionsPerPage)) },
                  (_, number) => (
                    <Pagination.Item
                      key={number + 1}
                      active={number + 1 === currentPage}
                      onClick={() => paginate(number + 1)}
                    >
                      {number + 1}
                    </Pagination.Item>
                  )
                )}
            </Pagination>


          </div></>
      )}
    </DashLayout>
  );
};

export default PermissionsView;
