import React, { useState, useEffect, useRef } from 'react';
import { countries } from 'country-data';

import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

function ContactSalesForm() {
    const [fullName, setFullName] = useState('');
    const [company, setCompany] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [workEmail, setWorkEmail] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const [information, setInformation] = useState('');

    const dropdownRef = useRef(null);

    const normalize = (str) =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    useEffect(() => {
        const term = normalize(searchTerm);
        let filtered;
        if (term === '') {
            filtered = countries.all;
        } else {
            filtered = countries.all.filter(country =>
                normalize(country.name).startsWith(term)
            );
        }
        setFilteredCountries(filtered);
    }, [searchTerm]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);
        document.body.classList.add('dark-bg');
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.body.classList.remove('dark-bg');
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({
            fullName,
            company,
            jobTitle,
            workEmail,
            country: searchTerm,
            information,
        });
    };

    const handleCountrySelect = (countryName) => {
        setSearchTerm(countryName);
        setShowDropdown(false);
    };

    return (
        <>
            <FlNav />
            <div className="w-100 contactWrapper">
                <div className="fl-contact-container">
                    <h1>Contact Sales</h1>
                    <p>
                        Tell us who you are and how we can help you.<br />
                        Or write us to <a href="mailto:sales@1010bex.com">sales@1010bex.com</a>
                    </p>

                    <form onSubmit={handleSubmit} className="fl-contact-form">
                        <div>
                            <label>Full Name</label>
                            <input
                                type="text"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                placeholder="John Doe"
                            />
                        </div>

                        <div>
                            <label>Company</label>
                            <input
                                type="text"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder="Company Name"
                            />
                        </div>

                        <div className="fl-contact-row">
                            <div>
                                <label>Job Title</label>
                                <input
                                    type="text"
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    placeholder="Ej: Marketing Manager"
                                />
                            </div>

                            <div>
                                <label>Work email</label>
                                <input
                                    type="email"
                                    value={workEmail}
                                    onChange={(e) => setWorkEmail(e.target.value)}
                                    placeholder="you@company.com"
                                />
                            </div>
                        </div>

                        <div className="fl-contact-dropdown" ref={dropdownRef}>
                            <label>Country</label>
                            <input
                                type="text"
                                value={searchTerm}
                                onFocus={() => setShowDropdown(true)}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setShowDropdown(true);
                                }}
                                placeholder="Choose your country"
                            />
                            {showDropdown && (
                                <ul className="fl-contact-dropdown-list">
                                    {filteredCountries.length > 0 ? (
                                        filteredCountries.map((c) => (
                                            <li
                                                key={c.alpha2}
                                                onClick={() => handleCountrySelect(c.name)}
                                            >
                                                {c.name}
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            {searchTerm.trim() === ''
                                                ? 'No hay países disponibles.'
                                                : 'No se encontraron países que coincidan.'}
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>

                        <div>
                            <label>What kind of information do you need?</label>
                            <textarea
                                value={information}
                                onChange={(e) => setInformation(e.target.value)}
                                placeholder="Escribe aquí la información que necesitas..."
                            />
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="fl-contact-submit-btn">
                                Contact Sales
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <FlFooter />
        </>
    );
}

export default ContactSalesForm;
