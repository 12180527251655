import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Form, Alert, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import CustomDeveloperSelect from '../DashboardComponents/CustomDeveloperSelect '

type Developer = { id: string; email: string; checked: boolean };

type AddTeamProps = {
  organization: number;
  handleClose: () => void;
  developers: Developer[];
};

const AddTeamModal = ({ developers, toggleDeveloper }) => (
  <div>
    {developers.length === 0 ? (
      <p>No developers available</p>
    ) : (
      developers.map((dev) => (
        <Form.Check
          key={dev.id}
          type="switch"
          id={`developer-${dev.id}`}
          label={dev.email || "No Email"}
          onChange={() => toggleDeveloper(dev.id)}
        />
      ))
    )}
  </div>
);

const AddTeamForm: FunctionComponent<AddTeamProps> = ({
  organization,
  handleClose,
  developers,
}) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDevelopers, setSelectedDevelopers] = useState<string[]>([]);

  const toggleDeveloper = (id: string) => {
    setSelectedDevelopers((prev) =>
      prev.includes(id) ? prev.filter((devId) => devId !== id) : [...prev, id]
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      team_name: cardholder,
      team_description: cardNumber,
      developers: selectedDevelopers,
    };

    try {
      const result = await ObexRequestHandler.post(
        `/create_dev_team?organization=${organization}`,
        payload,
        true
      );

      if (result.success) {
        handleClose();
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error creating team:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initiallySelected = developers.filter((dev) => dev.checked).map((dev) => dev.id);
    setSelectedDevelopers(initiallySelected);
  }, [developers]);

  return (
    <Row>
      <Col md="12">
        <Form onSubmit={handleSubmit}>
          <Form.Row className="mb-3">
            <Form.Label column sm="12">
              Team Name
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Name"
              value={cardholder}
              onChange={(e) => setCardholder(e.target.value)}
            />
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12">
              Team Description
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Description"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12">
              Select Developers
            </Form.Label>
            <CustomDeveloperSelect
                  developers={developers} // Pasa los developers disponibles
                  onAdd={(developer) => console.log('Added developer:', developer)} // Callback al añadir
                  onRemove={(developer) => console.log('Removed developer:', developer)} // Callback al eliminar
                />
          </Form.Row>
          <Form.Row>
            <Col md='6'>
              <Button type="submit" variant="primary" className="w-100">
                Add Team
                {loading && <Spinner animation="border" size="sm" />}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  );
};

export default AddTeamForm;
