import React from 'react';
import LearningDrawer from '../../newViews/avapCloud/widgets/LearningDrawer';

interface DrawerProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  name?: string; // Nueva prop opcional `name`
  subtitle?: string;
  children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleSidebar, name, children, subtitle }) => {
  return (
    <>
      {/* Overlay de fondo oscuro */}
      {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      <div className={`lateral drawer overflowX ${isOpen ? 'open' : ''}`}>

      <div className='drawer-content-card'>
        <div className="drawer-header">
          <div className="drawerWrapper">
          <span>{'' || ''}</span>
            <span style={{ fontSize:'18px', fontWeight:700 }}>{name || 'Drawer'}</span>
          </div>
          {false && <button style={{ marginTop:'20px' }} className="close-btn" onClick={toggleSidebar}>✕</button>}
        </div>

        {/* Contenido dinámico */}
        <div className="drawer-content">
          {children}
          </div>
        </div>
        <div style={{ height:'10px' }}></div>
        <LearningDrawer />
      </div>
    </>
  );
};

export default Drawer;
