import React from "react";
import logos from "../../../assets/img/landingPage/LogosFamilia_pie.svg"

const Footer = () => {
    return (
        <footer className="fl-footer">
            {/* Primera fila */}
            <div className="fl-footer-row">
                <p className="fl-footer-logos">
                    <img src={logos} alt="" />
                </p>
            </div>

            {/* Segunda fila */}
            <div className="fl-footer-row grid">
                <div>
                    <h4>Products & Services</h4>
                    <ul>
                        <li>AVAP Advance Virtual API Programming</li>
                        <li>AVAP Virtual Server</li>
                        <li>AVAP Gateway</li>
                        <li>AVAP Dev Studio 2024</li>
                        <li>AVAP Cloud</li>
                        <li>AVAP Collaborative Network</li>
                    </ul>
                </div>
                <div>
                    <h4>AVAP ID</h4>
                    <ul>
                        <li>AVAP ID</li>
                    </ul>

                    <h4>AI Brunix</h4>
                    <ul>
                        <li>AI Brunix</li>
                    </ul>
                </div>
                <div>
                    <h4>Resources</h4>
                    <ul>
                        <li>AVAP Developer Portal</li>
                        <li>AVAP Alexandria</li>
                        <li>AVAP Communities</li>
                    </ul>
                </div>
                <div>
                    <h4>Company</h4>
                    <ul>
                        <li>About us</li>
                    </ul>
                </div>
                <div>
                    <h4>Legal</h4>
                    <ul>
                        <li>T&C</li>
                        <li>Privacy notice</li>
                    </ul>
                </div>
            </div>

            {/* Tercera fila */}
            <div className="fl-footer-row flex fl-border-top">
                <div>
                    <p>Corporate head office</p>
                    <p>1010BEX, CORP 440 N Wolfe RD Sunnyvale 94085, CA</p>
                </div>
                <div>
                    <p>Contact us: sales@1010bex.com</p>
                    <p className="fl-footer-socials">
                        <a href="#">LinkedIn</a>
                        <a href="#">YouTube</a>
                        <a href="#">Twitter</a>
                        <a href="#">Facebook</a>
                    </p>
                </div>
            </div>

            {/* Cuarta fila */}
            <div className="fl-footer-row center fl-border-top">
                <p>&copy; 2024 1010BEX, CORP. All rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
