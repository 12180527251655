import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';

const GenericSelectableList = ({
    items = [], // Lista de elementos
    onAdd = (item) => console.log('Added:', item), // Callback al añadir un elemento (argumento obligatorio)
    onRemove = (item) => console.log('Removed:', item), // Callback al eliminar un elemento (argumento obligatorio)
    searchKeys = [], // Claves de búsqueda
    renderLabel = (item) => item.label || item.name, // Cómo renderizar un elemento
    placeholder = "Select an item...", // Placeholder del input
    noResultsText = "No items found", // Texto si no hay resultados
    multiOption = true, // Permitir selección múltiple o no
    selectedItems = [],
    setSelectedItems = (list) => console.log(list)
}) => {
    const [isOpen, setIsOpen] = useState(false); // Controla el estado del dropdown
    const [searchTerm, setSearchTerm] = useState(''); // Controla el término de búsqueda
    //const [selectedItems, setSelectedItems] = useState([]); // Elementos seleccionados
    const [selectedItem, setSelectedItem] = useState(null); // Elemento seleccionado único (para `multiOption: false`)

    const inputRef = useRef(null);

    useEffect(() => {
      if (isOpen && inputRef.current) {
        inputRef.current.focus(); // Asigna el foco al control
      }
    }, [isOpen]);
    
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSelect = (item) => {
        if (multiOption) {
            // Lógica para selección múltiple
            if (!selectedItems.some((selected) => selected.id === item.id)) {
                const updatedSelection = [...selectedItems, item];
                setSelectedItems(updatedSelection);
                if (typeof onAdd === 'function') onAdd(item); // Llama al callback de adición
            }
        } else {
            // Lógica para selección única
            setSelectedItem(item);
            if (typeof onAdd === 'function') onAdd(item); // Llama al callback de adición
        }
        setIsOpen(false); // Cierra el menú
    };

    const handleRemove = (item) => {
        const updatedSelection = selectedItems.filter((selected) => selected.id !== item.id);
        setSelectedItems(updatedSelection);
        if (typeof onRemove === 'function') onRemove(item); // Llama al callback de eliminación
    };

    // Filtra los elementos según las claves de búsqueda
    const filteredItems = items.filter((item) =>
        searchKeys.some((key) =>
            (item[key]?.toLowerCase() || '').includes(searchTerm.toLowerCase())
        )
    );


    return (
        <div className="custom-dev-wrapper">
            {/* Input principal */}
            <InputGroup>
                <Form.Control 
                    type="text"
                    placeholder={placeholder}
                    value={
                        multiOption
                            ? "" // Deja vacío el input para selección múltiple
                            : selectedItem
                            ? renderLabel(selectedItem) // Muestra el elemento seleccionado en modo único
                            : ""
                    }
                    onFocus={toggleDropdown}
                    readOnly={!multiOption} // Permitir solo lectura para modo único
                />
            </InputGroup>

            {/* Dropdown personalizado */}
            {isOpen && (
                <div className="custom-dropdown">
                    {/* Campo de búsqueda */}
                    <div className="search-input-wrapper">
                        <Form.Control
                            ref={inputRef}
                            style={{ marginTop:'-36px', zIndex:99999999 }}
                            type="text"
                            placeholder={placeholder}
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onBlur={()=>{
                                setTimeout(() => {
                                    setIsOpen(false)
                                  }, 200);
                                
                            }}
                        />
                    </div>

                    {/* Lista de opciones */}
                    <div className="dropdown-options">
                        {filteredItems.length > 0 ? (
                            filteredItems.map((item) => (
                                <div
                                    key={item.id}
                                    className="dropdown-option"
                                    onClick={() => handleSelect(item)}
                                >
                                    {renderLabel(item)}
                                </div>
                            ))
                        ) : (
                            <div className="no-results">{noResultsText}</div>
                        )}
                    </div>
                </div>
            )}

            {/* Lista de elementos seleccionados (solo para multiOption) */}
            {multiOption && (
                <div className="selected-developers mt-3">
                    {selectedItems.map((item) => (
                        <div key={item.id} className="selected-developer-item">
                            <span>{renderLabel(item)}</span>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleRemove(item)}
                            >
                                Remove
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GenericSelectableList;
