import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";

import editicon from '../../assets/img/ui_icons/cld_edit_table.svg';
import deleteIcon from '../../assets/img/ui_icons/cld_delete.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";

import devIcon from '../../assets/img/ui_icons/cld_org-s.svg'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";

import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'

const RolesManagement = () => {
  const [roles, setRoles] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [search, setSearch] = useState('');
  const [searchV, setSearchV] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [localSelectedGA, setSelectedGA] = useState([]);
  const [listaRecuperados, setlistaRecuperados] = useState([]);

  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
      console.log("+++")
      console.log(ga);
      console.log(localSelectedGA);
      //if (cardholder && cardNumber && localSelectedGA.length>0) setSubmitDisabled(false);
    }
  };

  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };

  const [permissions, setPermissions] = useState([]);

  const getRolePermits = async (role_id) => {
    try {

      //setloadDevs(true);
      setLoading(true);
      setlistaRecuperados([]);

      const permits = (
        await ObexRequestHandler.get(`/iam/get_permits_role?rol_id=${role_id}`, {}, true)).data || [];

      setlistaRecuperados(permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

    setLoading(false);

  }



  const getPermits = async (filtro) => {
    try {

      //setloadDevs(true);
      setLoading(true);
      setPermissions([]);

      const permits = (
        await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];

      const filter_permits = permits.filter(element =>
        element.permit_code.toLowerCase().includes(filtro.toLowerCase())
      );
      setPermissions(filtro === '' ? permits : filter_permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

    setLoading(false);

  }

  const getRoles = async (filtro) => {
    try {
      setLoading(true);
      //setloadDevs(true);

      setRoles([]);

      const roles = (
        await ObexRequestHandler.get('/iam/get_roles', {}, true)).data || [];

      const filter_roles = roles.filter(element =>
        element.role_name.toLowerCase().includes(filtro.toLowerCase())
      );
      setRoles(filtro === '' ? roles : filter_roles);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }
    setLoading(false);
  }

  useEffect(() => {

    getPermits('');
    getRoles('');

  }, []);

  const handleSearchT = (e) => setSearch(e);
  const handleSearch = (e) => setSearch(e.target.value);
  const handleSearchV = (e) => setSearchV(e.target.value);

  const filteredRoles = roles.filter(
    (role) =>
      (filterType === 'all' || role.type === filterType) &&
      role.role_name.toLowerCase().includes(search.toLowerCase())
  );

  const openDrawer = (role) => {
    setlistaRecuperados([]);
    if (role) {
      console.log(role);
      getRolePermits(role.role_id);
      setCurrentRole(role);
      setIsEditing(true);
    } else {
      setCurrentRole({
        role_name: '',
        role_description: '',
        permissions: { read: false, write: false, delete: false },
        type: 'Custom',
      });
      setIsEditing(false);
    }
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setCurrentRole(null);
    setIsEditing(false);
    setIsDrawerOpen(false);
  };

  const togglePermission = (permission) => {
    setCurrentRole((prevRole) => ({
      ...prevRole,
      permissions: {
        ...prevRole.permissions,
        [permission]: !prevRole.permissions[permission],
      },
    }));
  };

  const handleAddPermit = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedGA.some((p) => p.id === permit.id)) {
      setSelectedGA((prev) => [...prev, permit]);
    }
  };

  const handleRemovePermit = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedGA((prev) => prev.filter((p) => p.id !== permit.id));
  };


  const handleSubmit = async () => {
    if (isEditing) {
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role.id === currentRole.id ? currentRole : role
        )
      );

      
      console.log(currentRole);
      console.log(roles);
      console.log(localSelectedGA)


    } else {
      setRoles((prevRoles) => [
        ...prevRoles,
        { ...currentRole, id: prevRoles.length + 1 },
      ]);

       
          /* CREACION DEL ROL */
        console.log(currentRole);
        console.log(roles);
        console.log(localSelectedGA)

        const payload3 = {
          permits: localSelectedGA,
          role_name: currentRole.role_name,
          role_description: currentRole.role_description
        }

        const result2 = await ObexRequestHandler.post('/iam/create_role', payload3)
        console.log(result2);

    }



    closeDrawer();
  };

  return (
    <DashLayout sider={DashRouters} active={'roles'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">IAM <span style={{color:'#949498', fontSize:'14px'}}> /  Roles Management</span></span>
            </div>

          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          <Button style={{ width: '225px' }} className="customButton-blue px-4 float-right  resBtn" onClick={() => openDrawer(null)}>
            Create a Role
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">



          <span className='cld_sectionTitle'>Roles</span>


          <div className="searchContainer">
                    <Form.Control id='buscador' required type="text" value={searchV} placeholder="Search by Role name"
                      onChange={handleSearchV} />
                    <Button className="customButton-transparent px-4"
                      onClick={()=>handleSearchT(searchV)}><FontAwesomeIcon icon={faSearch} size="1x" />
                    </Button>
                  </div>

{/*}
          <Row className="searchContainer mb-3" >
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Search roles..."
                value={search}
                onChange={handleSearch}
              /> /*}
            </Col>
            {/*}
            <Col md={4}>
              <Form.Control
                as="select"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <option value="all">All</option>
                <option value="Predefined">Predefined</option>
                <option value="Custom">Custom</option>
              </Form.Control>
            </Col>*/}
        {/*}  </Row> */}

          <Table className="cld_tableWrapper" responsive>
            <tbody>
              <tr className="headerRow">
                <th style={{ width: '20%' }} className="textBold">Role Name</th>
                <th className="textBold"># Users</th>
                <th style={{ width: '25%' }} className="textBold">Description</th>
                <th className="textBold">Type</th>
                <th className="textBold"># Permissions</th>
                
                <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
              </tr>

              {filteredRoles.map((role) => (
                <tr className="tableRow" key={role.id}>
                  <td>{role.role_name}</td>
                  <td className="devsItem" style={{ marginTop:'10px', backgroundColor: 'var(--color-background-from)', textAlign:'center' }} >{role.user_count}</td>
                  <td>{role.role_description}</td>
                  <td>{role.role_type}</td>
                  <td className="devsItem" style={{ marginTop:'10px', backgroundColor: 'var(--color-background-from)', textAlign:'center' }} >{role.permit_count}</td>
                  
                  <td>
                    <div className="actionsWrapper">

                      <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '20px' }}>
                        <Dropdown.Toggle className="organizations" >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item key={1} onClick={() => openDrawer(role)}>Edit</Dropdown.Item>
                          <Dropdown.Item key={2} onClick={() => { console.log(role) }}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/*}
                      <a className="edit-link" onClick={() => openDrawer(role)}>
                        <span
                          className="editIcon"
                          style={{ backgroundImage: `url(${editicon})` }}
                        ></span>
                        Edit
                      </a>
                      <a className="edit-link ml-2">
                        <span
                          className="editIcon"
                          style={{ backgroundImage: `url(${deleteIcon})` }}
                        ></span>
                        Delete
                      </a>
                      */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <Drawer
        isOpen={isDrawerOpen}
        toggleSidebar={closeDrawer}
        name={isEditing ? `Edit Role: ${currentRole?.role_name}` : 'New Role'}
        subtitle='' /*{isEditing ? "Edit Role" : "Create Role1"}*/
      >
        <Form>
          <Form.Group>
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              type="text"
              value={currentRole?.role_name || ''}
              onChange={(e) => 
                setCurrentRole((prevRole) => ({
                  ...prevRole,
                  role_name: e.target.value,
                }))
              }
              placeholder="Enter role name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={currentRole?.role_description || ""}
              onChange={(e) =>
                setCurrentRole((prevRole) => ({
                  ...prevRole,
                  role_description: e.target.value,
                }))
              }
              placeholder="Enter description"
            />
          </Form.Group>
          <Form.Group>
            {/*<Form.Label>Permissions</Form.Label>*/}


            <Form.Group controlId="selectGA" className="mb-3">
              <Form.Label>Select Permits</Form.Label>
              <GenericSelectableList
                items={permissions} 
                searchKeys={['permit_code', 'description']} // Búsqueda por código o descripción
                onAdd={handleAddPermit} 
                onRemove={handleRemovePermit}
                renderLabel={(permit) => `${permit.permit_code} (${permit.description})`}
                placeholder="Select permissions..."
                multiOption={true} // Permitir selección múltiple
                selectedItems={listaRecuperados}
                setSelectedItems={setlistaRecuperados}
              />
            </Form.Group>



            {/*}
            <div>
              {["read", "write", "delete"].map((permission) => (
                <div key={permission} className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`toggle-${permission}`}
                    checked={currentRole?.permissions?.[permission] || false}
                    onClick={() => togglePermission(permission)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`toggle-${permission}`}
                  >
                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                  </label>
                </div>
              ))}
            </div>

*/}

          </Form.Group>
          <Row>
            <Col md='6'>
              <Button
                className="mt-3 w-100"
                variant="primary"
                onClick={handleSubmit}
              >
                {isEditing ? 'Update Role' : 'Create Role'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </DashLayout>
  );
};

export default RolesManagement;
