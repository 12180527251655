import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import FetchingSpinner from '../basicComponents/loaderModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { parseToCoin } from '../../services/AmountService';
import { useHistory } from 'react-router-dom';
import ilustra_upgrade from '../../assets/img/upgrade.png';
import info_icon from '../../assets/img/infoicon-2.png';
import AddCreditCard from './addCreditCard';
import UpgradePlanModal from './UpgradePlanModal';

type AddAPISProps = {
  shown: boolean,
  MaxAPIS: number,
  ActualAPIS: number,
  currentSuscription: number,
  onCloseAction: () => void,
  onSuccess: () => void
}

const AddAPISModal:FunctionComponent<AddAPISProps> = (props) => {
    
  const defaultSelectedPlan = { id: 0, monthly_price: 0, requests_number:0, title:'' } // Seteamos esto para por defecto no tener ninguno marcado
  const { shown, onCloseAction, onSuccess, MaxAPIS, ActualAPIS, currentSuscription } = props;

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [requestsPlans, setRequestsPlans] = useState([]);
  const [storagePlanSelected, setSelectedPlan] = useState(defaultSelectedPlan);
  const [paymentEnable, setPaymentEnable] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [tiTle, setTitle] = useState('Add APIs to your account');
  const [subtTle, setSubTitle] = useState('Choose your package and continue to checkout.');
  const [Upgrade, setUpgrade] = useState(false);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [card_id_stripe, setCardID] = useState('');
  const [newadded_card, setAddedCard] = useState('');


  const clearAll = () => {
    setError('');
    setSuccess('');
    setPaymentSuccess(false);
    setSelectedPlan(defaultSelectedPlan)
    onCloseAction();
  }


  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if(cards_.some(e => e.id == c.id)){
            //console.log('exists');
          }else{
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);
  
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };
  

  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value=='addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();
  
  }
  const getPlans = async () => {
    try {
      setFetchingPlans(true);
      const plans = (await ObexRequestHandler.get('/extra_apis_plans')).data;
      setRequestsPlans(plans);
    } catch (error) {
      console.error('ERROR FETCHING STORAGE PLANS ', error);
    }
    setFetchingPlans(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { extraDbSizePlanId: storagePlanSelected.id, card_stripe_id: card_id_stripe};
      const result = await ObexRequestHandler.post('/extra_apis_plans', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentEnable(false);
      setTitle('Purchase confirmed')
      setSubTitle('Slots added to your account')
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA STORAGE ', error)
    }
    setLoading(false);
  }

  const removeSelectedPlan = () => setSelectedPlan(defaultSelectedPlan);

  // TODO MOSTRAR DECIMALES

  var abledPlans = [];

for (var i = 0, max = requestsPlans.length; i < max; i += 1) {
 
    if (requestsPlans[i].extra_project_size<=(MaxAPIS-ActualAPIS)) {
      abledPlans.push(requestsPlans[i]);
      if (Upgrade!=false){
        setUpgrade(false);
        setTitle('ADD APIS TO YOUR ACCOUNT');
        setSubTitle('Choose your package and continue to checkout.');
      }
    
    }
 
}



  const requestsPlansRows = abledPlans.map(r => (
    <Row className={'addon addons-border py-3 mb-4 position-relative' + (storagePlanSelected.id === r.id && ' addons-active')}>
    {storagePlanSelected.id === r.id && <FontAwesomeIcon icon={faCheck} />}
      
      {/*}
      <Col md="12" className="addons-title">
        <span>{r.title}</span>
      </Col>
*/}
      <Col>
        <Row>
          <Col md="6">
          <div>
            <span  style={{fontWeight:600, color:'#E7E7ED'}} >{r.description}</span>
            </div>
            <div style={{marginTop:'5px'}}>
            <span style={{fontSize:'12px', color:'#E7E7ED'}}>{/*<FontAwesomeIcon icon={faInfo}/>*/}
            <img 
            style = {{
              height: '10px',
              marginRight: '3px',
              marginTop: '-2px'
            }}
            src={info_icon} alt="" height={"100%"} />
              Taxes and feed may apply</span>
            </div>
          </Col>
          <Col className="price-addons">
            <span>{`${parseToCoin(r.monthly_price)}/mo*`}</span>
          </Col>
          <Col>
            {storagePlanSelected.id === r.id ? 
              <button value="5" className="customButton-new-remove w-100 textBold " onClick={removeSelectedPlan}>Remove</button>:
              <button value="5" className="customButton-new w-100 textBold" onClick={() => setSelectedPlan(r)}>Add</button>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  ))

  const selectPlanModalContent = (
    <Container fluid className="mh-100">
      {fetchingPlans ? <FetchingSpinner/> : requestsPlansRows}
      <Row className="mb-2">

      {!Upgrade ?
        <Col className="text-start" style={{display:'flex', maxWidth:'180px', marginLeft:'auto', marginRight:'-15px'}}>
          {storagePlanSelected.id > 0
            ? <button style={{marginLeft:'auto', marginRight:'0%', paddingLeft:'0px', paddingRight:'0px'}} className="w-100 customButton-blue with-add textBold" onClick={() => setPaymentEnable(true)}>Checkout</button>
            : <button disabled style={{marginLeft:'auto', marginRight:'0%', paddingLeft:'0px', paddingRight:'0px'}} className="w-100 customButton-blue textBold">Select an Option</button>
          }
         
        </Col>:

          <>
          {/*}
          <Row>
          <Col md="5" className="my-3 mx-auto">
            <Image src={ilustra_upgrade} fluid />
          </Col>
          </Row>
          <Col className="text-center">
              <Button className="obex-btn btn-orange with-add mr-4" style={{ width:'120px' }} onClick={clearAll}>Close</Button>
            </Col>
            <Col className="text-center">
              <Button className="obex-btn btn-green with-add mr-4" style={{ width:'120px' }} onClick={() => { history.push('/plan_subcription/upgrade_plan'); } }>Upgrade</Button>
            </Col>
*/}
            
            <UpgradePlanModal 
          showModal={Upgrade} 
          text={''} 
          onCloseModal={onCloseAction} 
          onCancelModal={onCloseAction} 
          currentSuscription={currentSuscription}
          onSuccess={()=>{
            setTitle('Congratulations');
            setSubTitle('You has upgraded your plan')
            setPaymentSuccess(true);
            setPaymentEnable(false);
          }}
          />


            </>
        }
      </Row>
      {(!Upgrade  && false) &&
      <Row>
        <Col className="text-center">
          <span>*Taxes and fees may apply</span>
        </Col>
      </Row>
}

    </Container>
  )
  useEffect(() => {
    getPlans();
    getCards();
    if (abledPlans.length==0) {
      setUpgrade(true);
      setTitle('You need to Upgrade your Plan');
      setSubTitle('In order to add more APIs to you account');
    }
  }, [])

  return(
    <>
    <Modal dialogClassName="addons-dialog themeDialog w-75 obex-dialog"
   container={document.getElementById('dashboardWrapper')}
      show={shown} 
      onHide={clearAll}
      animation={false}>
         {fetchingPlans ? <FetchingSpinner/> :
      <><Modal.Header closeButton className="border-0 pb-0">
      <Modal.Title className="text-start w-100">
        <Row>
          <Col className="addons-title">
            <span>{tiTle.toUpperCase()}</span>
          </Col>
        </Row>
        <Row>
          <Col md="10" className=" text-start modal-subtitle">
            {paymentEnable ?
              <span style={{color:'#B9B9C1'}} className="text-start d-block">Review your payment and proceed.</span> :
              <span style={{color:'#B9B9C1'}} className="text-start d-block">{subtTle}</span>}
          </Col>
        </Row>
      </Modal.Title>
    </Modal.Header>
          <Modal.Body>
            {paymentEnable ?
              <PaymentConfirmation
                actionSubmit={makePayment}
                handleGoBack={() => setPaymentEnable(false)}
                handleAnnualPayment={null}
                nameServiceSelected={`Buying ${storagePlanSelected.title} package.`}
                amountMonth={storagePlanSelected.monthly_price}
                amountYear={storagePlanSelected.monthly_price}
                processingPayment={loading}
                success={success}
                error={error}
                isBlue={true}
                cards={cards_}
                handleCard={handleCard}
                addedCard={newadded_card} 
                spanish={false}/>
              : (paymentSuccess ? <PaymentSuccess blue={true} onCloseAction={()=>{clearAll();onSuccess()}} paymentDescription={''} paymentType={''} /> : selectPlanModalContent)}
          </Modal.Body></>
}
    </Modal>
    {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={false}  spanish={false}/>}
    </>
  );
}

export default AddAPISModal;


