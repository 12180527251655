import React, { useEffect, useState } from "react";
import { Form, Button, Table, Dropdown, DropdownButton, Row, Col } from "react-bootstrap";
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";


type EnvironmentFormProps = {
  id?: string;
  ambient?: {id: number, name:string};
  port?: string;
  visibility?: boolean;
  whiteListEnabled?: boolean;
  whiteList?: string[];
  apiProducts?: any[];
  onSubmit: (env: {
    id?: string;
    ambient: {id: number, name:string};
    port: string;
    visibility: boolean;
    whiteListEnabled: boolean;
    whiteList: string[];
    apiProducts: any[];
  }) => void;
};

const EnvironmentForm: React.FC<EnvironmentFormProps> = ({
  id,
  ambient = {id:0, name:'local' },
  port = "",
  visibility = false,
  whiteListEnabled = false,
  whiteList = [],
  apiProducts = [],
  onSubmit,
}) => {
  const [selectedAmbient, setSelectedAmbient] = useState(ambient);
  const [selectedPort, setSelectedPort] = useState(port);
  const [localVisibility, setLocalVisibility] = useState(visibility);
  const [localWhiteListEnabled, setLocalWhiteListEnabled] = useState(whiteListEnabled);
  const [localWhiteList, setLocalWhiteList] = useState<string[]>(whiteList);

  const [localAlias, setAlias] = useState('');
  

  const [AMBIENTS, setAMBIENTS] = useState([]);

  const [PRODUCTS, setPRODUCTs] = useState([]);


  const [listaRecuperados3, setlistaRecuperados3] = useState([])


const getPRODUCTS = () =>{
  setPRODUCTs(apiProducts);
  console.log("lk")
  console.log(apiProducts)
}

  const getAMBIENTS = async () =>{

    const gas = await ObexRequestHandler.get('/avap_ambients');
    console.log(gas);
    setAMBIENTS(gas.data);

  }


  useEffect(() => {
    getAMBIENTS();
    //getPRODUCTS();
    getGatewayAPIProducts();
  }, []);

  
  const ambientOptions = [
    { id: "1", name: "Development" },
    { id: "2", name: "Testing" },
    { id: "3", name: "Production" },
  ];

  const portOptions = [
    { id: '1', name: '44' },
    { id: '2', name: '80' },
    { id: '3', name: '81' },
    { id: '4', name: '82' },
    { id: '5', name: '83' },
    { id: '5', name: '84' },
    { id: '5', name: '85' },
  ];

  const handleAddToWhiteList = () => {
    setLocalWhiteList([...localWhiteList, ""]);
  };

  const handleUpdateWhiteListItem = (index: number, value: string) => {
    const updatedWhiteList = [...localWhiteList];
    updatedWhiteList[index] = value;
    setLocalWhiteList(updatedWhiteList);
  };

  const handleRemoveFromWhiteList = (index: number) => {
    setLocalWhiteList(localWhiteList.filter((_, i) => i !== index));
  };



  const getGatewayAPIProducts = async () => {

    
    const apiproduct_configs = await ObexRequestHandler.get(`/gateway/get_gateway_products?organization=${SessionService.ORGANIZATION}`)

    const apiproduct = apiproduct_configs.data.map((prox) => ({ 
      id: prox.id, 
      name: prox.avap_api_product_name,
      type: prox.api_type_name
    }));

    console.log(apiproduct);

    setPRODUCTs(apiproduct);

    console.log('-.-.--.-.-.-.-.-.-.');

  };

  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      ambient_id: selectedAmbient.id,
      port: Number(selectedPort),
      visibility: localVisibility,
      white_list_enabled: localWhiteListEnabled,
      alias: localAlias,
      organization: SessionService.ORGANIZATION,
      whitelist: localWhiteList,
      api_products: listaRecuperados3
    }

console.log(payload);

    await ObexRequestHandler.post('/gateway/set_gateway_ambients', payload);

    onSubmit({
      id,
      ambient: selectedAmbient,
      port: selectedPort,
      visibility: localVisibility,
      whiteListEnabled: localWhiteListEnabled,
      whiteList: localWhiteList,
      apiProducts: localWhiteList
    });
  };

  return (
    <Form onSubmit={handleSubmit}>

<Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Alias</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter alias"
          value={localAlias}
          onChange={(e) => setAlias(e.target.value)}
        />
      </Form.Group>


      <Form.Group controlId="ambient" className="mb-3">
        <Form.Label>Ambient</Form.Label>
        <GenericSelectableList
          items={AMBIENTS}
          searchKeys={['name']}
          onAdd={(selected) => setSelectedAmbient(selected)}
          renderLabel={(item) => item.name.replace('staging', 'test')}
          placeholder="Select Ambient"
          multiOption={false} // Solo una selección
        />
      </Form.Group>

      <Form.Group controlId="port" className="mb-3">
        <Form.Label>Port</Form.Label>
        <GenericSelectableList
          items={portOptions} 
          searchKeys={["name"]} 
          onAdd={(selected) => setSelectedPort(selected.name)}
          renderLabel={(item) => item.name} 
          placeholder="Select Port"
          multiOption={false} // Solo una selección
        />
      </Form.Group>

      <Form.Group controlId="visibility" className="mb-3">
        <Form.Label>Visibility</Form.Label>
        <Form.Check
          type="switch"
          label={localVisibility ? "Visible" : "Hidden"}
          checked={localVisibility}
          onChange={(e) => setLocalVisibility(e.target.checked)}
        />
      </Form.Group>

      <Form.Group controlId="whiteList" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Enable White List"
          checked={localWhiteListEnabled}
          onChange={(e) => setLocalWhiteListEnabled(e.target.checked)}
        />
      </Form.Group>

      {localWhiteListEnabled && (
        <div>
          <Table className="tableForm" hover>
            <thead>
              <tr>
                <th>White List Item</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {localWhiteList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Enter value"
                      value={item}
                      onChange={(e) => handleUpdateWhiteListItem(index, e.target.value)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveFromWhiteList(index)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="link" onClick={handleAddToWhiteList}>
            Add to White List
          </Button>
        </div>
      )}

      <Form.Group controlId="ambient" className="mb-3">
        <Form.Label>API Product List</Form.Label>
        <GenericSelectableList
          items={PRODUCTS}
          searchKeys={['name']}
          //onAdd={(selected) => setSelectedAmbient(selected.name)}
          renderLabel={(item) => item.name}
          placeholder="Select API Product"
          multiOption={true} // Solo una selección


          selectedItems={listaRecuperados3}
          setSelectedItems={setlistaRecuperados3}




        />
      </Form.Group>

      <Row>
        <Col md='6'>
          <Button variant="primary" className="mt-3 w-100" type="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EnvironmentForm;
